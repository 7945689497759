import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const newsletters = [
  'SFR August 2024 Newsletter',
  // 'SFR September 2024 Newsletter',
  // 'SFR October 2024 Newsletter',
  // Add more newsletter names here
];

const NewsletterGallery = () => {
  return (
    <div>
      <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />
      <div className="container mx-auto px-4 py-24">
        <h1 className="text-5xl font-bold text-center mb-12 main_text">Newsletters</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {newsletters.map((newsletterName) => {
            // Import the thumbnail image
            let thumbnail;
            try {
              thumbnail = require(`../../images/Newsletters/${newsletterName}/thumbnail.png`);
            } catch (error) {
              console.error(`Thumbnail not found for ${newsletterName}`);
              thumbnail = 'path/to/default/thumbnail.png'; // Use a default thumbnail
            }

            return (
              <div
                key={newsletterName}
                className="hover:shadow-lg p-4 border rounded main_text"
              >
                <Link to={`/Newsletters/${encodeURIComponent(newsletterName)}`}>
                  <LazyLoadImage
                    alt={newsletterName}
                    effect="blur"
                    src={thumbnail}
                    className="rounded"
                  />
                  <h2 className="font-bold mt-2">{newsletterName}</h2>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsletterGallery;
