import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Humza from "../../../images/Humza.webp";
import Kareem from "../../../images/Kareem.webp";
import Yazan from "../../../images/Yazan.webp";
import Deniz from "../../../images/Deniz.webp";
import Carnegie from "../../../images/SFR_Carnegie_Logo.svg";
import Bellaire from "../../../images/SFR_Bellaire_Logo.svg";
import DeBakey from "../../../images/SFR_DeBakey_Logo.svg";
import Tamu from "../../../images/SFR_Tamu_Logo.svg";
import Baylor from "../../../images/SFR_Baylor_Logo.svg";
import Challenge from "../../../images/SFR_Challenge_Logo.svg";
import Lamar from "../../../images/SFR_Lamar_Logo.svg";
import UH from "../../../images/SFR_UH_Logo.svg";
import Westside from "../../../images/SFR_Westside_Logo.svg";
import Nabila from "../../../images/Nabila.png";
import Katharine from "../../../images/Katharine.png";
import Aisha from "../../../images/Aisha.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Ali from '../../../images/Ali.webp';
import Asad from '../../../images/Asad.webp';
import Hira from '../../../images/Hira.webp';
import Homeschool from '../../../images/SFR_Homeschool_Logo.svg'


function Team() {

  const boardMembers = [
    {
      src: Katharine,
      name: "Katharine Gratwick",
      title: "Development Economist",
      bio: "Katharine Gratwick is a development economist living and working in Houston, TX. She specializes in sub-Saharan African electricity policy. She joined SFR in 2023 and is thrilled to learn and work alongside a group of committed and concerned individuals who believe our strength lies in our diversity, our compassion, and ultimately our ability to serve one another."
    },
    {
      src: Aisha,
      name: "Aisha Koroma",
      title: "Mental Health & Social Services Professional",
      bio: "Aisha Koroma, born in Sierra Leone, is a trauma-responsive mental health and social services professional dedicated to empowering marginalized populations. A war survivor and refugee, she migrated to the US and now aids refugee integration. A licensed therapist with degrees in psychology and counseling, she has spearheaded mental health initiatives, co-authored works on refugee experiences, and developed innovative GBV programs. Aisha is a renowned advocate for refugee voices globally."
    },
    {
      src: Nabila,
      name: "Nabila Ansar",
      title: "Managing Attorney",
      bio: "Nabila is the Managing Attorney of the Law Office of Nabila Ansari. She assists her clients in resolving complex legal problems in the areas of business and corporate governance, risk mitigation and contracts. Nabila is a longtime Houston resident and has been active in supporting her community through volunteer work, including refugee support services, for many years. She joined the board in 2024."
    },
  ];

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="hover:bg-[#464646]"
      />

      <div className="flex flex-col mb-24">
        <div>
          <h1 className="text-5xl font-bold main_text mt-24 mb-24 text-center">
            Meet the Team
          </h1>
          <div className="grid grid-cols-1 gap-y-32 gap-x-20 lg:gap-x-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center mx-auto md:mx-[15vh] lg:px-8 lg:mx-auto main_text">
            {[
              { src: Humza, name: "Imran Humza Hanif", title: "President" },
              { src: Deniz, name: "Deniz Çalışkan", title: "Vice President" },
              { src: Hira, name: "Hira Malik", title: "Secretary" },
              { src: Ali, name: "Ali Faruqui", title: "Treasurer" },
              { src: Yazan, name: "Yazan Zarka", title: "International Relations" },
              { src: Asad, name: "As'ad Ansari", title: "Outreach Officer" },
              { src: Kareem, name: "Kareem Kabbani", title: "Web Developer" },
            ].map((member, index) => (
              <div key={index} className="mx-auto max-h-48">
                <LazyLoadImage
                  src={member.src}
                  alt={member.name}
                  className="max-h-48 max-w-48 min-h-48 min-w-48 lg:min-w-48 lg:min-h-48 rounded-full object-cover mx-auto"
                  effect="blur"
                />
                <h1 className="text-center font-semibold pt-3">{member.name}</h1>
                <h1 className="text-center font-semibold">{member.title}</h1>
              </div>
            ))}
          </div>

        </div>

        <div>
          <h1 className="text-5xl font-bold main_text mt-36 mb-24 text-center">
            Meet the Board
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-12 mx-[6vh] sm:mx-[15vh] lg:px-16 lg:mx-auto main_text">
            {boardMembers.map((member, index) => (
              <div key={index} className="flex flex-col md:flex-row bg-white shadow-md rounded-lg overflow-hidden mx-auto max-w-4xl md:max-w-none">
                <img
                  src={member.src}
                  className="h-48 w-48 rounded-full object-cover m-4 mx-auto md:mx-4"
                  alt={member.name}
                />
                <div className="p-4 flex flex-col justify-center">
                  <h1 className="text-xl font-semibold">{member.name}</h1>
                  <h2 className="text-md font-semibold">{member.title}</h2>
                  <p className="text-gray-700 mt-2">{member.bio}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h1 className="text-5xl font-bold main_text mt-24 mb-24 text-center">
            Active Branches
          </h1>
          <div className="grid grid-cols-2 gap-y-10 gap-x-20 md:grid-cols-3 lg:grid-cols-5 lg:gap-x-1 px-8 mx-auto md:mx-[15vh] lg:px-8 lg:mx-auto main_text">
            {[
              { src: Carnegie, name: "Carnegie Vanguard" },
              { src: Bellaire, name: "Bellaire" },
              { src: DeBakey, name: "DeBakey" },
              { src: Tamu, name: "Texas A&M" },
              { src: Baylor, name: "Baylor Medicine" },
              { src: Challenge, name: "Challenge" },
              { src: Lamar, name: "Lamar" },
              { src: UH, name: "University of Houston" },
              {src : Westside, name: "Westside"},
              {src : Homeschool, name: "Homeschool"}
            ].map(branch => (
              <div className="mx-auto max-h-48">
                <img
                  src={branch.src}
                  className="max-h-28 max-w-28 min-h-28 min-w-28 lg:min-w-44 lg:min-h-44 rounded-full object-cover mx-auto"
                  alt={branch.name}
                />
                <h1 className="text-center font-semibold pt-3 text-sm">{branch.name}</h1>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Team;
