import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const extractImages = (context) => {
  const keys = context.keys();
  // console.log('Context Keys:', keys);
  return keys.map(context).filter(src => src);
};

const events = {
  'Peace Farm SFR Feb 2024': require.context('../../compressedGalleryImages/Peace Farm SFR Feb 2024', false, /\.(webp)$/),
  'Covid-19 Relief Effort SFR Feb_May 2021': require.context('../../compressedGalleryImages/Covid-19 Relief Effort SFR Feb_May 2021', false, /\.(webp)$/),
  'Covid-19 Relief Effort SFR Jul_Oct_Nov 2020': require.context('../../compressedGalleryImages/Covid-19 Relief Effort SFR Jul_Oct_Nov 2020', false, /\.(webp)$/),
  'P4P August 2024': require.context('../../compressedGalleryImages/P4P August 2024', false, /\.(webp)$/),
  'Peace Farm SFR Aug 2024': require.context('../../compressedGalleryImages/Peace Farm SFR Aug 2024', false, /\.(webp)$/),

  'Health Fare May 2023': require.context('../../compressedGalleryImages/Health Fare May 2023', false, /\.(webp)$/),
  'Health Fare May 2024': require.context('../../compressedGalleryImages/Health Fare May 2024', false, /\.(webp)$/),
  'Peace Farm SFR Sept 2024': require.context('../../compressedGalleryImages/Peace Farm SFR Sept 2024', false, /\.(webp)$/),
  'SFR 2023 Additional Photos': require.context('../../compressedGalleryImages/SFR 2023 Additional Photos', false, /\.(webp)$/),
  // ... other events
};

const IndividualEventGallery = () => {
  const navigate = useNavigate();
  const { eventName } = useParams();

  const goBack = () => {
    navigate('/eventgallery');
  };

  if (!events.hasOwnProperty(eventName)) {
    return (
      <div>
        <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />
        <div className="container mx-auto px-4 py-24">
          <button onClick={goBack} className="btn-main text-white mb-8 font-bold py-2 px-4 md:py-3 md:px-4 md:text-lg rounded-full mt-2">«Back to Event Gallery</button>
          <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">No images found for event: {eventName}</h1>
        </div>
        <Footer />
      </div>
    );
  }

  const imagesContext = events[eventName];
  const images = extractImages(imagesContext);

  return (
    <div>
      <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />
      <div className="container mx-auto px-4 py-24">
        <button onClick={goBack} className="btn-main text-white mb-8 font-bold py-2 px-4 md:py-3 md:px-4 md:text-lg rounded-full mt-2">«Back to Event Gallery</button>
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">{eventName} Gallery</h1>
        <div className="columns-2 md:columns-3 lg:columns-4 gap-4 space-y-4">
          {images.map((imageSrc, index) => (
            <div key={index} className="break-inside-avoid rounded overflow-hidden">
              <LazyLoadImage
                src={imageSrc}
                alt={`Image ${index + 1}`}
                className="w-full h-auto object-cover"
                effect="blur"
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndividualEventGallery;
