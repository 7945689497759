import React, { useState } from "react";
import { CgMenuRightAlt } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useBranch } from '../../components/BranchProvider/BranchProvider.js';  // Import the BranchProvider

const Navbar = ({
  colorSFR = "text-white",
  colorRest = "text-white",
  colorBox = "bg-white",
  colortextBox = "text-black",
  colorhover = "hover:bg-gray-100",
}) => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };
  const { branch } = useBranch();

  const [aboutDropdown1, setAboutDropdown1] = useState(false);

  const toggleAboutDropdown1 = (state) => {
    setAboutDropdown1(state);
  };

  const [aboutDropdown2, setAboutDropdown2] = useState(false);

  const toggleAboutDropdown2 = (state) => {
    setAboutDropdown2(state);
  };

  const [activeMenu1, setActiveMenu1] = useState(true);

  const handleSubMenuToggle1 = () => {
    setActiveMenu1(!activeMenu1);
  };

  const [activeMenu2, setActiveMenu2] = useState(true);

  const handleSubMenuToggle2 = () => {
    setActiveMenu2(!activeMenu2);
  };

  return (
    <div
      className={`flex justify-between items-center h-32 max-w-[90vw] mx-auto px-2 ${colorRest}`}
    >
      <Link to="/" className={`flex-grow md:text-3xl text-2xl font-bold ${colorSFR}`}>
        Students for Refugees 
        <br/>
        <p className="md:text-2xl text-xl">{branch && branch !== "National Team" ? `@${branch}` : ""}</p>
      </Link>
      {/* Change md:flex to lg:hidden to switch at 900px */}
      <ul className="hidden lg:flex lg:font-bold lg:flex-nowrap lg:min-w-[600px]">
        <li
          className="p-2 relative"
          onMouseEnter={() => toggleAboutDropdown1(true)}
          onMouseLeave={() => toggleAboutDropdown1(false)}
        >
          <Link>ABOUT</Link>
          {aboutDropdown1 && (
            <ul
              className={`absolute left-0 top-full ${colorBox} ${colortextBox} w-28 h-24 text-center text-sm z-50`}
            >
              <Link to="/about/vision">
                <li className={`pt-3 pl-2 text-left ${colorhover}`}>VISION </li>
              </Link>

              <Link to="/about/team">
                <li className={`pt-2 pl-2 text-left ${colorhover}`}>TEAM </li>
              </Link>

              <Link to="/about/contact">
                <li className={`pt-2 pl-2 text-left ${colorhover}`}>CONTACT </li>
              </Link>
            </ul>
          )}
        </li>
        <li className="p-2">
          <Link to="/donate">DONATE</Link>
        </li>
        <li className="p-2">
          <Link to="/volunteer">VOLUNTEER</Link>
        </li>
        <li
          className="p-2 relative"
          onMouseEnter={() => toggleAboutDropdown2(true)}
          onMouseLeave={() => toggleAboutDropdown2(false)}
        >
          <Link>RESOURCES</Link>
          {aboutDropdown2 && (
            <ul
              className={`absolute left-0 top-full ${colorBox} ${colortextBox} w-60 h-44 text-center text-sm z-50`}
            >
              <Link to="/resources/general">
                <li className={` pt-6 pl-2 text-left ${colorhover}`}>GENERAL WEBSITES</li>
              </Link>

              <Link to="/resources/africa">
                <li className={`pt-2 pl-2 text-left ${colorhover}`}>AFRICAN REFUGEE CRISIS</li>
              </Link>

              <Link to="/resources/middleeast">
                <li className={` pt-2 pl-2 text-left ${colorhover}`}>MIDDLE EASTERN REFUGEE CRISIS</li>
              </Link>

              <Link to="/resources/policy">
                <li className={` pt-2 pl-2 text-left ${colorhover}`}>REFUGEE MIGRATION POLICY</li>
              </Link>
            </ul>
          )}
        </li>
        <li className="p-2">
          <Link to="/eventgallery">EVENT GALLERY</Link>
        </li>
        <li className="p-2">
          <Link to="/newsletters">NEWSLETTERS</Link>
        </li>
      </ul>

      <div onClick={handleNav} className="block lg:hidden">
        {!nav ? <div /> : <CgMenuRightAlt size={30} />}
      </div>

      <div>
        <ul
          className={
            !nav
              ? "fixed pl-9 text-lg left-0 top-0 text-white w-[89%] h-full border-r border-r-black bg-black ease-in-out duration-500 z-10"
              : "fixed pl-9 text-lg left-[-100%] top-0 -translate-x-full w-[89%] h-full border-r border-r-black bg-black ease-in-out duration-1000"
          }
        >
          <li className="pt-9">
            <Link to="/">HOME</Link>
          </li>
          <li className="relative">
            <button onClick={handleSubMenuToggle1}>
              <Link>ABOUT »</Link>
            </button>
            <ul
              className={
                !activeMenu1
                  ? "fixed pl-9 text-lg left-0 text-white top-0 w-[89%] h-full border-r border-r-black bg-black ease-in-out duration-500 z-10"
                  : "fixed pl-9 text-lg left-[-100%] top-0 -translate-x-full w-[89%] h-full border-r border-r-black bg-black ease-in-out duration-1000"
              }
            >
              <div
                className="pt-9 text-3xl main_text"
                onClick={handleSubMenuToggle1}
              >
                <Link>«Back</Link>
              </div>
              <div className="pt-4">
                <Link to="/about/vision">VISION</Link>
              </div>
              <div>
                <Link to="/about/team">TEAM</Link>
              </div>
              <div>
                <Link to="/about/contact">CONTACT</Link>
              </div>
            </ul>
          </li>
          <li>
            <Link to="/donate">DONATE</Link>
          </li>
          <li>
            <Link to="/volunteer">VOLUNTEER</Link>
          </li>
          <li className="relative">
            <button onClick={handleSubMenuToggle2}>
              <Link>RESOURCES »</Link>
            </button>
            <ul
              className={
                !activeMenu2
                  ? "fixed pl-9 text-lg text-white left-0 top-0 w-[89%] h-full border-r border-r-black bg-black ease-in-out duration-500 z-10"
                  : "fixed pl-9 text-lg left-[-100%] top-0 -translate-x-full w-[89%] h-full border-r border-r-black bg-black ease-in-out duration-1000"
              }
            >
              <div
                className="pt-9 text-3xl main_text"
                onClick={handleSubMenuToggle2}
              >
                <Link>«Back</Link>
              </div>
              <div className="pt-4">
                <Link to="/resources/general">GENERAL WEBSITES</Link>
              </div>
              <div>
                <Link to="/resources/africa">AFRICAN REFUGEE CRISIS</Link>
              </div>
              <div>
                <Link to="/resources/middleeast">
                  MIDDLE EASTERN REFUGEE CRISIS
                </Link>
              </div>
              <div>
                <Link to="/resources/policy">REFUGEE MIGRATION POLICY</Link>
              </div>
            </ul>
          </li>
          <li>
            <Link to="/eventgallery">EVENT GALLERY</Link>
          </li>
          <li>
          <Link to="/newsletters">NEWSLETTERS</Link>
        </li>
        </ul>

        <ul
          className={
            !nav
              ? "fixed right-0 top-0 w-[11%] h-full border-r border-r-black main_bg ease-in-out duration-100"
              : "fixed right-[-100%] top-0 w-[11%] h-full border-r border-r-black main_bg ease-in-out duration-1000"
          }
        >
          <li className="flex justify-center items-center pt-7">
            <div onClick={handleNav}>
              {!nav ? <AiOutlineClose size={25} /> : <div />}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
