import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useWindowWidth } from '@react-hook/window-size';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const IndividualNewsletter = () => {
  // Call Hooks at the top level
  const { newsletterName } = useParams();
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const windowWidth = useWindowWidth();
  const pdfWidth = Math.min(windowWidth * 0.9, 800); // 90% of window width or 800px max

  const goToPrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () => setPageNumber((prev) => Math.min(prev + 1, numPages));

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        goToPrevPage();
      } else if (event.key === 'ArrowRight') {
        goToNextPage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [goToPrevPage, goToNextPage]);

  // Handle PDF file loading
  let pdfFile;
  let pdfError = false;

  try {
    // Construct the path to the PDF file
    const sanitizedNewsletterName = newsletterName;
    pdfFile = require(`../../pdfs/${sanitizedNewsletterName}/${sanitizedNewsletterName}.pdf`);
  } catch (error) {
    console.error(`Could not load PDF for newsletter ${newsletterName}:`, error);
    pdfError = true;
  }

  if (pdfError) {
    return (
      <div>
        <Navbar
          colorSFR="main_text"
          colorRest="text-[#9999A3]"
          colorBox="bg-black"
          colortextBox="text-white"
          colorhover="hover:bg-[#464646]"
        />
        <div className="container mx-auto px-4 py-24">
          <button onClick={() => navigate(-1)} className="btn-main mb-4 py-2 px-4 rounded text-white">
            « Back to Newsletters
          </button>
          <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">
            No PDF found for newsletter: {newsletterName}
          </h1>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="hover:bg-[#464646]"
      />
      <div className="container mx-auto px-4 py-24">
        <button onClick={() => navigate(-1)} className="btn-main mb-4 py-2 px-4 rounded text-white">
          « Back to Newsletters
        </button>
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">
          {newsletterName}
        </h1>
        <div className="flex flex-col items-center">
          <div className="mb-4 flex items-center">
            <button
              onClick={goToPrevPage}
              disabled={pageNumber <= 1}
              className="btn_lm py-1 px-3 rounded mr-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              ‹ Prev
            </button>
            <span className="main_text">
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={pageNumber >= numPages}
              className="btn_lm py-1 px-3 rounded ml-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next ›
            </button>
          </div>
          <div className="pdf-container">
          <Document
            file={pdfFile}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              setPageNumber(1);
            }}
            loading={<div>Loading PDF...</div>}
            onLoadError={(error) => console.error('Error while loading PDF:', error)}
          >
            <Page
              pageNumber={pageNumber}
              width={pdfWidth}
              renderTextLayer={false} // Disable the text layer
              renderAnnotationLayer={false} // Optional: Disable annotations layer if not needed
            />
          </Document>
          </div>
          <div className="mt-4 flex items-center">
            <button
              onClick={goToPrevPage}
              disabled={pageNumber <= 1}
              className="btn_lm py-1 px-3 rounded mr-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              ‹ Prev
            </button>
            <span className="main_text">
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={pageNumber >= numPages}
              className="btn_lm py-1 px-3 rounded ml-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next ›
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndividualNewsletter;
