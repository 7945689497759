import React from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'

function OurVision() {
  return (
    <div>
      <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />

      <div className=' flex flex-col  justify-center items-center mx-auto h-screen mt-24 md:mt-28 lg:mt-16'>
        <p className='font-semibold'>OUR VISION</p>
        <h1 className='pt-2 font-bold text-3xl md:text-5xl text-center w-[25vh] sm:w-[30vh] md:w-[35vh]'>Making A Difference</h1>
        <h2 className='italic pt-16 font-semibold text-sm md:text-base w-[40vh] sm:w-[45vh] md:w-[55vh] text-center'>As of 2024, the global refugee population has surged to over 35 million (UNHCR), with more than half being children under 18.</h2>
        <p className='pt-2 w-[40vh] sm:w-[45vh] md:w-[55vh] text-sm md:text-base justify-center text-justify'>
          The mission of Students for Refugees is to champion awareness and education about the ongoing refugee crisis caused by persecution, conflict, violence, and human rights violations. 
          We strive to deepen understanding of migration issues—pre-migration, transmigration, and post-migration. Our organization empowers students to volunteer, advocate, 
          and foster community participation for this vulnerable population, in collaboration with numerous agencies dedicated to assisting refugees. Through engagement, our 
          students will gain invaluable insights into resilience and perseverance from the stories of the refugees they encounter. Our goal is to build a international network 
          of students committed to promoting human rights, peace, and advocacy for displaced refugee children and their families, starting in Houston, which proudly hosts the 
          largest number of resettled refugees in the United States.
        </p>
        <p className='pt-40 text-sm md:text-base w-[40vh] sm:w-[45vh] md:w-[55vh] items-center justify-center text-center'>Imran Humza Hanif</p>
        <p className='pt-4 pb-24 text-sm md:text-base w-[40vh] sm:w-[45vh] md:w-[55vh] items-center justify-center text-center'>Founder and President</p>
      </div>
      <Footer />
    </div>
  )
}

export default OurVision
