import React, { useState, useEffect } from 'react';

// Automatically import all images from the directory
function importAll(r) {
  return r.keys().map(r);
}

const imagesPeaceFarmAug2024 = importAll(require.context('../../compressedGalleryImages/Peace Farm SFR Aug 2024', false, /\.(webp)$/));
const imagesPeaceFarmFeb2024 = importAll(require.context('../../compressedGalleryImages/Peace Farm SFR Feb 2024', false, /\.(webp)$/));
const Civid19Feb = importAll(require.context('../../compressedGalleryImages/Covid-19 Relief Effort SFR Feb_May 2021', false, /\.(webp)$/));
const Civid19Jul = importAll(require.context('../../compressedGalleryImages/Covid-19 Relief Effort SFR Jul_Oct_Nov 2020', false, /\.(webp)$/));

const imagesHealthFareMay2023 = importAll(require.context('../../compressedGalleryImages/Health Fare May 2023', false, /\.(webp)$/));
const imagesHealthFareMay2024 = importAll(require.context('../../compressedGalleryImages/Health Fare May 2024', false, /\.(webp)$/));
const imagesPeaceFarmSept2024 = importAll(require.context('../../compressedGalleryImages/Peace Farm SFR Sept 2024', false, /\.(webp)$/));
const imagesSFR2023AdditionalPhotos = importAll(require.context('../../compressedGalleryImages/SFR 2023 Additional Photos', false, /\.(webp)$/));

// Combine all images into a single array
const allImages = [...imagesPeaceFarmAug2024, ...imagesPeaceFarmFeb2024, ...Civid19Feb, ...Civid19Jul, ...imagesHealthFareMay2023, ...imagesHealthFareMay2024,
                   ...imagesPeaceFarmSept2024, ...imagesSFR2023AdditionalPhotos
];

const RandomImageGallery = () => {
  const [currentImages, setCurrentImages] = useState([]);

  // Function to get 4 random images from the array
  const getRandomImages = () => {
    const shuffled = allImages.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 1); // Select first 4 images
  };

  useEffect(() => {
    // Initially set random images
    setCurrentImages(getRandomImages());

    // Update images every 10 seconds
    const interval = setInterval(() => {
      setCurrentImages(getRandomImages());
    }, 8000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='flex justify-center items-center'>
      <div className='max-w-[90vw] justify-center'>
        {currentImages.map((image, index) => (
          <div
            key={index}
            className='w-[70vw] sm:w-[60vw] lg:w-[30vw] h-[35vh] lg:h-[32vh] shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 ease-in-out'
          >
            <img
              src={image}
              alt={`Random ${index}`}
              className='w-full h-full object-cover'
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RandomImageGallery;
